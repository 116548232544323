export default class WorkScope {
  constructor(
    _id,
    _contractorName,
    _contractorLicenseNumber,
    _contractorEstimate = [],
    _alternateContractorEstimate = [],
    _location,
    _estimatedSize,
    _type,
    _estimatedCost,
    _approximateLength,
    _description,
    _estimatedArea,
    _fixtures,
    _underfloorPlumbing,
    _estimatedCountRepaired,
    _estimatedCountReplaced,
    _treesRemoved,
    _repairTypes,
    _repairReplace,
    _WorkScope,
    _wiringWorkScope,
    _changeStatus,
    _familyOwner,
  ) {
    this.id = _id;
    this.contractorName = _contractorName;
    this.contractorLicenseNumber = _contractorLicenseNumber;
    this.contractorEstimate = _contractorEstimate[''];
    this.alternateContractorEstimate = _alternateContractorEstimate[''];
    this.location = _location;
    this.estimatedSize = _estimatedSize;
    this.type = _type;
    this.estimatedCost = _estimatedCost;
    this.approximateLength = _approximateLength;
    this.description = _description;
    this.estimatedArea = _estimatedArea;
    this.fixtures = _fixtures;
    this.underfloorPlumbing = _underfloorPlumbing;
    this.estimatedCountRepaired = _estimatedCountRepaired;
    this.estimatedCountReplaced = _estimatedCountReplaced;
    this.treesRemoved = _treesRemoved;
    this.repairTypes = _repairTypes;
    this.repairReplace = _repairReplace;
    this.WorkScope = _WorkScope;
    this.wiringWorkScope = _wiringWorkScope;
    this.changeStatus = _changeStatus;
    this.familyOwner = _familyOwner;
  }
}
